import Vue from 'vue';

import template from './index.html';

export default Vue.component('section-pagination', {
    template,
    data() {
        return {
            triggersPagination: [],
            triggersInverse: [],
            prevent: false,

            dotsQty: 0,

            point: 0,
            activeIndex: 0,

            inverse: false,
        }
    },
    mounted() {
        this.findElements();

        window.addEventListener('scroll', this.scrollHandler, false);
        window.addEventListener('resize', this.resizeHandler, false);

        this.resizeHandler();
        setTimeout(this.resizeHandler);
        setTimeout(this.resizeHandler, 500);
    },
    methods: {
        findElements() {
            this.triggersPagination = window.document.querySelectorAll('.pagination_trigger');
            this.dotsQty = this.triggersPagination.length;

            this.triggersInverse = window.document.querySelectorAll('.pagination-inverse-trigger');
        },

        scrollHandler() {
            this.calculatePosition();
        },
        resizeHandler() {
            this.prevent = !window.matchMedia("(min-width: 1024px)").matches;

            this.point = this.$refs.dotsContainer.getBoundingClientRect().top + this.$refs.dotsContainer.getBoundingClientRect().height / 2;

            this.calculatePosition();
        },

        calculatePosition() {
            if (this.prevent) return;

            let activeIndex = this.activeIndex;
            for (let i = 0; i < this.triggersPagination.length; i++) {
                let trigger = this.triggersPagination[i];
                let triggetPoint = trigger.getBoundingClientRect().top;

                if (triggetPoint < this.point) {
                    activeIndex = i;
                }
            }
            this.activeIndex = activeIndex;


            let inverse = false;
            for (let i = 0; i < this.triggersInverse.length; i++) {
                let trigger = this.triggersInverse[i];
                let triggetPoint = trigger.getBoundingClientRect().top;

                if (triggetPoint < this.point) {
                    inverse = trigger.dataset.inverse;
                }
            }
            this.inverse = (inverse === 'true') ? true : false;
        }
    }
});