import Vue from 'vue';

import template from './index.html';

export default Vue.component('page-legacy', {
    template,
    props: ['navigation'],
    data() {
        return {
            navigationLocal: [],
        }
    },
    mounted() {
        let currentUrl = window.location.pathname;
        this.navigationLocal = this.navigation.map(item => {
            item.active = item.url === currentUrl;
            return item;
        });
    },
    methods: {

    }
});