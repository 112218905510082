// polyfill
import 'intersection-observer';

import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueScrollTo from 'vue-scrollto';
import VueObserveVisibility from 'vue-observe-visibility';
import lottie from 'lottie-web';


import './components';
import './directives';
import './filters';


Vue.use(Vuelidate);
Vue.use(VueScrollTo);
Vue.use(VueObserveVisibility);
lottie.setQuality('low');

export default new Vue({
    el: '#app-landing',
    computed: {
        currentYear() {
            return new Date().getFullYear();
        }
    },
    methods: {},
});