import Vue from 'vue';

import template from './index.html';

import {ScrollEmulator} from '../../classes/scroll-emulator';

export default Vue.component('landing', {
    template,
    data() {
        return {
            introShow: true,

            sloganSectionColored: false,
            sloganXsVisible: false,
            stickyFormBlockState: 0,
            stickyFormBlockFixedEnable: false,

            headerRed: false,
        }
    },
    mounted() {
        this.introSection();
    },
    computed: {
        windowHeight() {
            return window.innerHeight;
        }
    },
    methods: {
        introSection() {
            this.introShow = true;

            let scrollEmulator = new ScrollEmulator();
            scrollEmulator.scrollHandler = direction => {
                this.introShow = false;
            };
        },
        introSectionBeforeLeave() {
            window.document.body.classList.add('body_scroll_block');
        },
        introSectionAfterLeave() {
            let vm = this;

            TweenLite.to(vm.$refs.salesPlatformPhoneParallax, 0.5,
                {
                    y: 0,
                    onComplete() {
                        window.document.body.classList.remove('body_scroll_block');

                        vm.initSalesPlatformParallax();
                        vm.formStick();
                        vm.initSloganAnimation();
                        vm.initPerspectiveParallax();
                        vm.locationHashCheck();
                    }
                }
            );
        },

        initSalesPlatformParallax() {
            let ctrl = new ScrollMagic.Controller();

            let timeline = new TimelineLite();

            timeline.add([
                TweenLite.fromTo(this.$refs.salesPlatformPhoneParallax, 1, {y: 0}, {y: '-350%'}),
            ]);

            let scene = new ScrollMagic.Scene({
                triggerElement: this.$refs.salesPlatformFigure,
                duration: '100%',
                triggerHook: 0
            })
                .setTween(timeline)
                // .addIndicators()
                .addTo(ctrl)
            ;

            // function setSceneParamsByScreenSize() {
            //     let triggerHook = (window.innerWidth > 1024) ? 0.35 : 0.5;
            //     scene.triggerHook(triggerHook);
            // }
            //
            // setTimeout(setSceneParamsByScreenSize);
            // window.addEventListener('resize', setSceneParamsByScreenSize);
        },

        formStick() {
            this.$refs.formBlockEqualizer = window.document.querySelectorAll('.sticky_form_block_equalizer');

            let formResizeHandler = () => {
                this.stickyFormBlockFixedEnable = window.matchMedia("(min-width: 1024px)").matches;
                if (!this.stickyFormBlockFixedEnable) return;

                for (let i = 0; i < this.$refs.formBlockEqualizer.length; i++) {
                    this.$refs.formBlockEqualizer[i].style.height = this.$refs.stickyFormBlock.clientHeight + 'px';
                }
            };
            window.addEventListener('resize', formResizeHandler);
            setTimeout(formResizeHandler);

            ///////
            let ctrl = new ScrollMagic.Controller();

            new ScrollMagic.Scene({
                triggerElement: this.$refs.stickyFormBlockTrigger1,
                triggerHook: 1,
            })
                // .addIndicators()
                .addTo(ctrl)
                .on("start", event => {
                    if (event.scrollDirection === 'FORWARD') {
                        this.stickyFormBlockState = 1;
                    } else {
                        this.stickyFormBlockState = 0;
                    }
                })
            ;

            new ScrollMagic.Scene({
                triggerElement: this.$refs.stickyFormBlockTrigger2,
                triggerHook: 1,
            })
                // .addIndicators()
                .addTo(ctrl)
                .on("start", event => {
                    if (event.scrollDirection === 'FORWARD') {
                        this.stickyFormBlockState = 2;
                    } else {
                        this.stickyFormBlockState = 1;
                    }
                })
            ;
        },

        initSloganAnimation() {
            let ctrl = new ScrollMagic.Controller();

            let scene = new ScrollMagic.Scene({
                triggerElement: this.$refs.slogan,
                duration: '100%',
                triggerHook: 0.5,
            })
                .setPin(this.$refs.slogan)
                // .addIndicators()
                .addTo(ctrl)
                .on("progress", event => {
                    this.sloganSectionColored = event.progress > 0.5;

                    this.headerRed = this.sloganSectionColored;
                })
            ;

            let vm = this;
            let minDuration = 1200;
            function setSceneParamsByScreenSize() {
                scene.offset(vm.$refs.slogan.clientHeight / 2);

                let windowWidth = window.innerWidth;
                let windowHeight = window.innerHeight;
                let duration = windowHeight;
                if (windowWidth > 1000 && windowWidth > windowHeight) {
                    if (duration < minDuration) {
                        duration = minDuration;
                    }
                }
                scene.duration(duration);
            }

            setTimeout(setSceneParamsByScreenSize);
            setTimeout(setSceneParamsByScreenSize, 400);
            setTimeout(setSceneParamsByScreenSize, 1000);
            window.addEventListener('resize', setSceneParamsByScreenSize);


            new ScrollMagic.Scene({
                triggerElement: this.$refs.sloganInternalAnimationTrigger,
                triggerHook: 1,
            })
                // .addIndicators()
                .addTo(ctrl)
                .on("start", event => {
                    if (event.scrollDirection === 'REVERSE') {
                        this.sloganXsVisible = false;
                    } else if (event.scrollDirection === 'FORWARD') {
                        this.sloganXsVisible = true;
                    }
                })
            ;


            new ScrollMagic.Scene({
                triggerElement: this.$refs.sloganColoredHeaderTriggerEnd,
                triggerHook: 0,
            })
            // .addIndicators()
                .addTo(ctrl)
                .on("start", event => {
                    if (event.scrollDirection === 'REVERSE') {
                        this.headerRed = true;
                    } else {
                        this.headerRed = false;
                    }
                })
            ;
        },

        initPerspectiveParallax() {
            let ctrl = new ScrollMagic.Controller();

            let timeline = new TimelineLite();

            timeline.add([
                TweenLite.to(this.$refs.buildingPerspective, 1, {y: '60%'}),
            ]);

            new ScrollMagic.Scene({
                triggerElement: this.$refs.buildingPerspectiveContainer,
                duration: '130%',
                triggerHook: 1,
            })
                .setTween(timeline)
                // .addIndicators()
                .addTo(ctrl)
            ;
        },

        locationHashCheck() {
            let hash = window.location.hash;
            if (hash === '#developers-section' || hash === '#agents-section') {
                setTimeout(() => {
                    this.$scrollTo(hash, 500, {
                        offset: -80,
                    });
                });
            }
        },
    }
});