import Vue from 'vue';

import template from './index.html';

export default Vue.component('header-component', {
    template,
    data() {
        return {
            theme: 'dark',

            triggers: [],

            mobileMenuVisible: false,
        }
    },
    mounted() {
        this.findTriggers();

        window.addEventListener('scroll', this.scrollHandler, false);
        window.addEventListener('resize', this.resizeHandler, false);

        this.calculatePosition();
        setTimeout(this.calculatePosition);
        setTimeout(this.calculatePosition, 500);
    },
    computed: {
        themeClass() {
            if (!this.theme) return null;

            return 'theme-' + this.theme;
        },
        homeLinkShow() {
            return window.location.pathname !== '/';
        },
    },
    methods: {
        classAdd(className) {
            let index = this.classList.indexOf(className);
            if (index < 0) {
                this.classList.push(className);
            }
        },
        classRemove(className) {
            let index = this.classList.indexOf(className);
            if (index > -1) {
                this.classList.splice(index, 1);
            }
        },

        findTriggers() {
            this.triggers = window.document.querySelectorAll('.header-theme-trigger');
        },

        scrollHandler() {
            this.calculatePosition();
        },
        resizeHandler() {
            this.calculatePosition();
        },

        calculatePosition() {
            if (!this.triggers.length) return;

            let clientBounding = this.$el.getBoundingClientRect();
            let targetPoint = clientBounding.top + clientBounding.height / 2;

            for (let i = 0; i < this.triggers.length; i++) {
                let trigger = this.triggers[i];
                let triggetPoint = trigger.getBoundingClientRect().top;

                if (triggetPoint < targetPoint) {
                    this.themeSwitch(trigger.dataset.theme);
                }
            }
        },

        themeSwitch(name) {
            this.theme = name;
        },

        mobileMenuToggle(value) {
            this.mobileMenuVisible = (typeof value === 'boolean') ? value: !this.mobileMenuVisible;
        },


        signInClick() {
            window.appWidgets.$emit('sign-in');
        },
    }
});