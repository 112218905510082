import Vue from 'vue';

import template from './index.html';

export default Vue.component('footer-component', {
    template,
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {

    }
});