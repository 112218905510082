import './landing';
import './rings-pulse';
import './rings-count-pulse';
import './header-component';
import './section-pagination';
import './footer-component';
import './count-up-inview';
import './form-subscribe';
import './page-legacy';
import './svg-player';
import './page-developers';
import './page-agents';
import './cookie-agree';