import Vue from 'vue';
import CountUp from 'countup.js';

import template from './index.html';

export default Vue.component('count-up-inview', {
    template,
    props: ['value'],
    data() {
        return {
            duration: 2,
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            let numAnim = new CountUp(this.$el, 0, +this.value, 0, this.duration, {
                useEasing: true,
                useGrouping: true,
                separator: '.',
                decimal: ' ',
            });

            new ScrollMagic.Scene({
                triggerElement: this.$el,
                triggerHook: 1,
            })
                // .addIndicators()
                .addTo(new ScrollMagic.Controller())
                .on('start', event => {
                    if (event.scrollDirection === 'FORWARD') {
                        numAnim.start();
                    } else {
                        numAnim.reset();
                    }
                })
            ;
        }
    }
});