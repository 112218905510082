import Vue from 'vue';

import template from './index.html';

export default Vue.component('page-developers', {
    template,
    data() {
        return {
            startSectionShow: false,
        }
    },
    mounted() {
        setTimeout(() => {
            this.startSectionShow = true;
        }, 1000);
    },
    methods: {

    }
});