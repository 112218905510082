import Vue from 'vue';
import {required, email} from 'vuelidate/lib/validators';

import template from './index.html';

export default Vue.component('form-subscribe', {
    template,
    validations: {
        form: {
            email: {
                required,
                email,
            }
        },
    },
    data() {
        return {
            form: {
                email: null,
            },
        }
    },
    mounted() {

    },
    methods: {
        validate() {
            this.$v.form.$touch();
            return !this.$v.form.$invalid;
        },

        formSubmit() {
            if (!this.validate()) return;
            // let query = queryString.stringify({
            //     email: this.formSubscribe.email
            // });
            //
            // window.location = '/sign-up?' + query;

            // this.$root.$emit('form-pre-register', this.form);
            window.appWidgets.$emit('pre-sign-up', this.form);
        },
    }
});