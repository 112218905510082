export class ScrollEmulator {
    constructor() {
        this.initMousewheel();
        this.initTouch();
        this.initKeyboards();
    }

    scrollHandler(direction){
        if (direction > 0) {
            console.log('down');
        } else if (direction < 0) {
            console.log('up');
        }
    }

    ////////
    initMousewheel() {
        this.mouseWheelHandler = this.mouseWheelHandler.bind(this);

        if (window.document.addEventListener) {
            window.document.addEventListener("mousewheel", this.mouseWheelHandler, false);
            window.document.addEventListener("DOMMouseScroll", this.mouseWheelHandler, false);
        } else {
            window.document.attachEvent("onmousewheel", this.mouseWheelHandler);
        }
    }
    mouseWheelHandler(event) {
        if (event.deltaY > 0) {
            this.scrollHandler(1);
        } else if (event.deltaY < 0) {
            this.scrollHandler(-1);
        }
    }
    ////////

    ////////
    initTouch() {
        let lastTouchY = 0;

        window.addEventListener("touchstart", (event) => {
            lastTouchY = event.touches[0].clientY;
        });

        window.addEventListener("touchmove", (event) => {
            let currentTouchY = event.touches[0].clientY;

            let diff = lastTouchY - currentTouchY;

            if (Math.abs(diff) < 30) return;

            if (diff < 0) {
                this.scrollHandler(-1);
            } else  if (diff > 0) {
                this.scrollHandler(1);
            }
        });
    }
    ////////


    ///////
    initKeyboards() {
        let keyCodes = {
            up: [38, 33],
            down: [40, 34],
        };

        window.addEventListener('keydown', (event) => {
            let keyCode = event.keyCode;

            if (keyCodes.up.indexOf(keyCode) > -1) {
                this.scrollHandler(-1);
            } else if (keyCodes.down.indexOf(keyCode) > -1) {
                this.scrollHandler(1);
            }
        });
    }

}