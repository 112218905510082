import Vue from 'vue';
import lottie from 'lottie-web';

lottie.setQuality('low');

import template from './index.html';

export default Vue.component('svg-player', {
    template,
    props: ['src', 'poster'],
    data() {
        return {
            player: null,
            isInView: false,
        }
    },
    mounted() {

    },
    computed: {
        showType() {
            // 0 - nothing; 1 - poster; 2 - player

            if (this.player) {
                return 2;
            }
            if (this.poster) {
                return 1;
            }
            return 0;
        },
    },
    methods: {
        init() {
            if (!this.src) return;

            let player = lottie.loadAnimation({
                container: this.$refs.container,
                renderer: 'svg',
                loop: true,
                path: this.src
            });

            player.addEventListener('data_ready', event => {
                this.player = player;
                this.player.play();
                
                this.isInviewHandler();
            });
        },

        visibilityChanged(isVisible, entry) {
            this.isInView = isVisible;
            this.isInviewHandler();
        },

        isInviewHandler() {
            if (this.player) {
                if (this.isInView) {
                    this.player.play();
                } else {
                    this.player.pause();
                }
            } else {
                if (this.src && this.isInView) {
                    // setTimeout(this.init, 1000);
                    this.init();
                }
            }
        },

    }
});