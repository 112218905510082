import Vue from 'vue';
import scrollMonitor from 'scrollmonitor';

export const InviewClassDirective = Vue.directive('inview-class', {
    bind(element, binding) {
        setTimeout(() => {
            let inviewWatcher = scrollMonitor.create(element, 0);
            let inview = inviewWatcher.isInViewport;

            classToggle(element, inview, binding.value);

            inviewWatcher.stateChange((event, data) => {
                inview = data.isInViewport;

                classToggle(element, inview, binding.value);

                if (binding.modifiers.once && inview) {
                    inviewWatcher.destroy();
                }
            });
        });
    }
});

function classToggle(element, inview, className = 'inview') {
    if (inview === true) {
        setTimeout(() => {
            element.classList.add(className);
        });
    } else if (inview === false) {
        setTimeout(() => {
            element.classList.remove(className);
        });
    }
}