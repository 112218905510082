import Vue from 'vue';
import cookies from 'browser-cookies';

import template from './index.html';

export default Vue.component('cookie-agree', {
    template,
    data() {
        return {
            show: false,
        }
    },
    mounted() {
        if (!cookies.get('cookie-accept')) {
            this.show = true;
        }
    },
    methods: {

        close() {
            this.show = false;
        },

        accept() {
            cookies.set('cookie-accept', 'true');
            this.close();
        },

    }
});